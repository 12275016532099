import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import {
    List,
    Datagrid,
    TextField as TextFieldRa,
    RichTextField,
    useShowController,
    TextInput,
    ShowButton,
    TopToolbar,
    Filter,
    useCheckAuth,
    useNotify,
    useRefresh,
    DatagridBody,
    SelectInput,
    DateInput,
    Edit,
    SimpleForm,
    ChipField,
    ArrayField,
    SingleFieldList,
    useRecordContext,
    FunctionField,
} from 'react-admin'
import {
    Button,
    Grid,
    TextField,
    Box,
    Paper,
    Tabs,
    Tab,
    Typography,
    AppBar,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Tooltip,
    TableCell,
    TableRow,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import BackButton from '../../../components/BackButton'
import api from '../../../Services/Api'
import { formatDate, formatDateTime, formatXmlDate } from '../../../functions'
import formatter from '../../../formatter'
import queueIcon from '@material-ui/icons/Warning'
import removedIcon from '@material-ui/icons/Delete'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import jsonExport from 'jsonexport/dist'
export const QueueIcon = queueIcon
export const RemovedIcon = removedIcon

const useStyles = makeStyles({
    root: {
        '& .MuiPaper-root': {
            marginTop: 10,
            padding: 10,
        },
        '& h4': {
            marginTop: 0,
        },
    },
    actions: {
        justifyContent: 'start', //botoes de ação na esquerda da tela
        '& button': {
            margin: 5,
        },
        '& a': {
            margin: 5,
        },
    },
    statusInput: {
        color: 'red',
    },
    warningInput: {
        color: 'orange',
    },
    successInput: {
        color: 'green',
    },
    headerCell: {
        padding: '10px',
    },
    rowCell: {
        backgroundColor: 'red',
        padding: '10px',
    },
})

export const OrderFilter = (props) => {
    const [insurerOptions, setInsurerOptions] = React.useState([])
    const [labelOptions, setLabelOptions] = React.useState([])

    useEffect(() => {
        ; (async () => {
            try {
                const result = await api.get(`admin/insurers`)
                setInsurerOptions(result.data)
            } catch (error) {
                console.log('Erro ao consultar insurers', error)
            }
        })()
            ; (async () => {
                try {
                    const result = await api.get(`admin/labels`)
                    setLabelOptions(result.data)
                } catch (error) {
                    console.log('Erro ao consultar labels', error)
                }
            })()
    }, [])

    return (
        <Filter {...props}>
            <TextInput label="Nro Pedido" source="id" alwaysOn />
            <TextInput label="Código Voucher" source="voucher" alwaysOn />
            <TextInput label="Email Cliente" source="ownerEmail" alwaysOn />
            <TextInput label="Nome Cliente" source="ownerName" alwaysOn />
            <TextInput label="Nome Segurado" source="name" alwaysOn />
            <TextInput label="E-mail Emissor" source="travelAgent" alwaysOn />
            <TextInput label="CPF Segurado" source="cpf" />
            <DateInput
                label="Data Ínício Pedido"
                source="startDate"
                style={{ width: 180 }}
            />
            <DateInput
                label="Data Fim Pedido"
                source="endDate"
                style={{ width: 180 }}
            />
            <SelectInput
                label="Seguradora"
                source="insurerId"
                choices={insurerOptions}
            />
            <SelectInput
                label="Situação"
                source="status"
                choices={[
                    { id: 11, name: 'Aguardando Pagamento' },
                    { id: 1, name: 'Aguardando Processamento' },
                    { id: 2, name: 'Pagamento Pré-aprovado' },
                    { id: 3, name: 'Pagamento Aprovado' },
                    { id: 6, name: 'Pendente/Antifraude' },
                    { id: 10, name: 'Pendente/Erro Pré-Pagamento' },
                    { id: 7, name: 'Pendente/Erro Pagamento' },
                    { id: 8, name: 'Pendente/Erro Reserva' },
                    { id: 4, name: 'Reservado' },
                    { id: 5, name: 'Concluído' },
                    { id: 12, name: 'Cancelado/Erro Estorno' },
                    { id: 13, name: 'Cancelado/Erro Voucher' },
                    { id: 9, name: 'Cancelado/Total' },
                    { id: 14, name: 'Removido' },
                ]}
                alwaysOn
            />
            <TextInput label="Código Cupom" source="coupon" />
            <TextInput
                label="Campanha Afiliado (cupom ou utm)"
                source="campaign"
            />
            <SelectInput label="Label" source="label" choices={labelOptions} />
        </Filter>
    )
}

export const AssistanceOrderList = (props) => {
    const classes = useStyles()
    // const [showMore, setShowMore] = useState(false);

    const StatusField = ({ record, source }) => {
        console.log(record, source)
        return (
            <span
                className={
                    record.status === 5
                        ? classes.successInput
                        : record.status === 12 &&
                            record.Payments[0].type === 'Invoice' &&
                            classes.warningInput
                            ? record.status === 12 &&
                            record.Payments[0].type === 'Invoice' &&
                            classes.warningInput
                            : [6, 7, 8, 9, 10, 12, 13, 14].includes(
                                record.status
                            ) && classes.statusInput
                }
            >
                {record[source]}
            </span>
        )
    }

    const IdField = ({ record, source }) => {
        let messages = ''
        if (record.extraRequests) messages += 'Possui solicitação extra.'
        if (record.supplierPayment === 'Pré-Pagamento')
            messages += 'Pré-Pagamento ao Hotel.'

        if (messages)
            return (
                <Tooltip title={<div>{messages}</div>}>
                    <span>{record[source]}*</span>
                </Tooltip>
            )
        return (
            <span>
                {record[source]}
                {messages ? '*' : ''}
            </span>
        )
    }

    const MyDatagridRow = ({ record, resource, id, children, basePath }) => (
        <TableRow key={id}>
            {React.Children.map(children, (field) => {
                let style = { padding: '10px' }
                if (
                    field.props.source === 'checkIn' ||
                    field.props.source === 'checkOut' ||
                    field.props.source === 'id'
                )
                    style.width = '75px'

                if (field.props.source === 'total') {
                    style.textAlign = 'left'
                    style.width = '100px'
                    style.minWidth = '100px'
                }

                if (field.props.source === 'roomsCount') {
                    style.width = '50px'
                    style.textAlign = 'center'
                }

                if (field.props.source === 'reservationId')
                    style.width = '150px'

                if (field.props.source === 'statusText') style.width = '200px'

                return (
                    <TableCell
                        key={`${id}-${field.props.source}`}
                        style={style}
                    >
                        {React.cloneElement(field, {
                            record,
                            basePath,
                            resource,
                        })}
                    </TableCell>
                )
            })}
        </TableRow>
    )

    const MyDatagridBody = (props) => (
        <DatagridBody {...props} row={<MyDatagridRow />} />
    )

    const MyDatagrid = (props) => (
        <Datagrid {...props} body={<MyDatagridBody />} />
    )

    const IdLink = (e) => {
        return <Link to={`/admin/assistanceOrders/${e.id}/show`}>{e.id}</Link>
    }

    const SelectedChip = () => {
        // const { selectedIds, onToggleItem } = useListContext();
        const record = useRecordContext()
        console.log(record)
        return (
            // <a href={`/admin/assistanceOrders`}>
            <ChipField
                source="shortName"
                clickable={false}
                // disabled={true}
                // clickable
                onClick={() => {
                    // window.location.href = `/admin/assistanceOrders/${record.id}/show`;
                    return
                }}
                style={{
                    pointerEvents: 'none',
                    backgroundColor: record.backgroundColor,
                    color: record.fontColor,
                }}
            />
            // </a>
        )
    }

    return (
        <List
            {...props}
            title="Lista de Pedidos"
            bulkActionButtons={false}
            sort={{ field: 'id', order: 'DESC' }}
            filters={<OrderFilter />}
            perPage={25}
        >
            <MyDatagrid classes={{ headerCell: classes.headerCell }}>
                <TextFieldRa source="createdAt" label="Data do Pedido" />
                <FunctionField label="Pedido" render={IdLink} />
                <RichTextField
                    source="vouchers"
                    label="Voucher"
                    sortable={false}
                />
                <StatusField
                    source="statusText"
                    sortBy="status"
                    label="Situação"
                    sortable={false}
                />
                {/* <TextFieldRa
                    source="fraudPreventionStatusText"
                    sortBy="fraudPreventionStatus"
                    label="Anti Fraude"
                /> */}
                {/* <TextFieldRa source="Insurer.name" label="Seguradora" sortable={false} /> */}
                {/* <TextFieldRa source="reservationAt" label="Emissão" /> */}
                <TextFieldRa source="checkIn" label="Início Ap." />
                <TextFieldRa source="checkOut" label="Fim Ap." />
                <TextFieldRa
                    source="destination"
                    label="Destino"
                    sortable={false}
                />
                <RichTextField
                    source="passengers"
                    label="Segurado(s)"
                    sortable={false}
                />
                <TextFieldRa
                    source="total"
                    sortBy="total"
                    label="Total"
                    textAlign="left"
                />
                <TextFieldRa
                    source="TravelAgent.email"
                    label="Agente Em."
                    sortable={false}
                    style={{ maxWidth: 150 }}
                />
                <ArrayField
                    source="labels"
                    clickable={false}
                    style={{ pointerEvents: 'none' }}
                >
                    <SingleFieldList>
                        <SelectedChip />
                    </SingleFieldList>
                </ArrayField>
                <ShowButton label="" />
            </MyDatagrid>
        </List>
    )
}

export const AssistanceOrderListQueue = (props) => {
    const classes = useStyles()

    const StatusField = ({ record, source }) => {
        return (
            <span
                className={
                    record.status === 5
                        ? classes.successInput
                        : record.status === 12 &&
                            record.Payments[0].type === 'Invoice' &&
                            classes.warningInput
                            ? record.status === 12 &&
                            record.Payments[0].type === 'Invoice' &&
                            classes.warningInput
                            : [6, 7, 8, 9, 10, 12, 13, 14].includes(
                                record.status
                            ) && classes.statusInput
                }
            >
                {record[source]}
            </span>
        )
    }

    const IdField = ({ record, source }) => {
        let messages = ''
        if (record.extraRequests) messages += 'Possui solicitação extra.'
        if (record.supplierPayment === 'Pré-Pagamento')
            messages += 'Pré-Pagamento ao Hotel.'

        if (messages)
            return (
                <Tooltip title={<div>{messages}</div>}>
                    <span>{record[source]}*</span>
                </Tooltip>
            )
        return (
            <span>
                {record[source]}
                {messages ? '*' : ''}
            </span>
        )
    }

    const MyDatagridRow = ({ record, resource, id, children, basePath }) => (
        <TableRow key={id}>
            {React.Children.map(children, (field) => {
                let style = { padding: '10px' }
                if (
                    field.props.source === 'checkIn' ||
                    field.props.source === 'checkOut' ||
                    field.props.source === 'id'
                )
                    style.width = '75px'

                if (field.props.source === 'total') {
                    style.textAlign = 'left'
                    style.width = '100px'
                    style.minWidth = '100px'
                }

                if (field.props.source === 'roomsCount') {
                    style.width = '50px'
                    style.textAlign = 'center'
                }

                if (field.props.source === 'reservationId')
                    style.width = '150px'

                if (field.props.source === 'statusText') style.width = '200px'

                return (
                    <TableCell
                        key={`${id}-${field.props.source}`}
                        style={style}
                    >
                        {React.cloneElement(field, {
                            record,
                            basePath,
                            resource,
                        })}
                    </TableCell>
                )
            })}
        </TableRow>
    )

    const MyDatagridBody = (props) => (
        <DatagridBody {...props} row={<MyDatagridRow />} />
    )
    const MyDatagrid = (props) => (
        <Datagrid {...props} body={<MyDatagridBody />} />
    )

    const MyButton = ({ record, source }) => {
        // console.log(record,source);
        return (
            <Button
                class=""
                component={Link}
                to={{ pathname: `/admin/assistanceOrders/${record.id}/show` }}
                target="_blank"
            >
                Pedido #{record.id}
            </Button>
        )
    }

    return (
        <List
            {...props}
            title="Pedidos em Fila"
            bulkActionButtons={false}
            sort={{ field: 'id', order: 'DESC' }}
            filters={<OrderFilter />}
            perPage={25}
        >
            <MyDatagrid classes={{ headerCell: classes.headerCell }}>
                <TextFieldRa source="createdAt" label="Data do Pedido" />
                <IdField source="id" label="Pedido" />
                <RichTextField
                    source="vouchers"
                    label="Voucher"
                    sortable={false}
                />
                <StatusField
                    source="statusText"
                    sortBy="status"
                    label="Situação"
                />
                {/* <TextFieldRa
                    source="fraudPreventionStatusText"
                    sortBy="fraudPreventionStatus"
                    label="Anti Fraude"
                /> */}
                <TextFieldRa
                    source="Insurer.name"
                    label="Seguradora"
                    sortable={false}
                />
                <TextFieldRa source="reservationAt" label="Emissão" />
                <TextFieldRa source="checkIn" label="Início" />
                <TextFieldRa source="checkOut" label="Fim" />
                <TextFieldRa
                    source="destination"
                    label="Destino"
                    sortable={false}
                />
                <RichTextField
                    source="passengers"
                    label="Segurado(s)"
                    sortable={false}
                />
                <TextFieldRa source="total" />
                <TextFieldRa
                    source="TravelAgent.email"
                    label="Agente"
                    sortable={false}
                />
                {/* <ShowButton/> */}
                {/* <OrderUrlField label="Pedido" source="id" target="_blank" rel="noopener noreferrer"/> */}
                <MyButton source="id" label="Ver Pedido" />
            </MyDatagrid>
        </List>
    )
}

export const AssistanceOrderListRemoved = (props) => {
    const classes = useStyles()

    const StatusField = ({ record, source }) => {
        return (
            <span
                className={
                    record.status === 5
                        ? classes.successInput
                        : record.status === 12 &&
                            record.Payments[0].type === 'Invoice' &&
                            classes.warningInput
                            ? record.status === 12 &&
                            record.Payments[0].type === 'Invoice' &&
                            classes.warningInput
                            : [6, 7, 8, 9, 10, 12, 13, 14].includes(
                                record.status
                            ) && classes.statusInput
                }
            >
                {record[source]}
            </span>
        )
    }

    const IdField = ({ record, source }) => {
        let messages = ''
        if (record.extraRequests) messages += 'Possui solicitação extra.'
        if (record.supplierPayment === 'Pré-Pagamento')
            messages += 'Pré-Pagamento ao Hotel.'

        if (messages)
            return (
                <Tooltip title={<div>{messages}</div>}>
                    <span>{record[source]}*</span>
                </Tooltip>
            )
        return (
            <span>
                {record[source]}
                {messages ? '*' : ''}
            </span>
        )
    }

    const MyDatagridRow = ({ record, resource, id, children, basePath }) => (
        <TableRow key={id}>
            {React.Children.map(children, (field) => {
                let style = { padding: '10px' }
                if (
                    field.props.source === 'checkIn' ||
                    field.props.source === 'checkOut' ||
                    field.props.source === 'id'
                )
                    style.width = '75px'

                if (field.props.source === 'total') {
                    style.textAlign = 'left'
                    style.width = '100px'
                    style.minWidth = '100px'
                }

                if (field.props.source === 'roomsCount') {
                    style.width = '50px'
                    style.textAlign = 'center'
                }

                if (field.props.source === 'reservationId')
                    style.width = '150px'

                if (field.props.source === 'statusText') style.width = '200px'

                return (
                    <TableCell
                        key={`${id}-${field.props.source}`}
                        style={style}
                    >
                        {React.cloneElement(field, {
                            record,
                            basePath,
                            resource,
                        })}
                    </TableCell>
                )
            })}
        </TableRow>
    )

    const MyDatagridBody = (props) => (
        <DatagridBody {...props} row={<MyDatagridRow />} />
    )
    const MyDatagrid = (props) => (
        <Datagrid {...props} body={<MyDatagridBody />} />
    )

    const MyButton = ({ record, source }) => {
        // console.log(record,source);
        return (
            <Button
                class=""
                component={Link}
                to={{ pathname: `/admin/assistanceOrders/${record.id}/show` }}
                target="_blank"
            >
                Pedido #{record.id}
            </Button>
        )
    }

    return (
        <List
            {...props}
            title="Pedidos em Fila"
            bulkActionButtons={false}
            sort={{ field: 'id', order: 'DESC' }}
            filters={<OrderFilter />}
            perPage={25}
        >
            <MyDatagrid classes={{ headerCell: classes.headerCell }}>
                <TextFieldRa source="createdAt" label="Data do Pedido" />
                <IdField source="id" label="Pedido" />
                <RichTextField
                    source="vouchers"
                    label="Voucher"
                    sortable={false}
                />
                <StatusField
                    source="statusText"
                    sortBy="status"
                    label="Situação"
                />
                {/* <TextFieldRa
                    source="fraudPreventionStatusText"
                    sortBy="fraudPreventionStatus"
                    label="Anti Fraude"
                /> */}
                <TextFieldRa
                    source="Insurer.name"
                    label="Seguradora"
                    sortable={false}
                />
                <TextFieldRa source="reservationAt" label="Emissão" />
                <TextFieldRa source="checkIn" label="Início" />
                <TextFieldRa source="checkOut" label="Fim" />
                <TextFieldRa
                    source="destination"
                    label="Destino"
                    sortable={false}
                />
                <RichTextField
                    source="passengers"
                    label="Segurado(s)"
                    sortable={false}
                />
                <TextFieldRa source="total" />
                <TextFieldRa
                    source="TravelAgent.email"
                    label="Agente"
                    sortable={false}
                />
                {/* <ShowButton/> */}
                {/* <OrderUrlField label="Pedido" source="id" target="_blank" rel="noopener noreferrer"/> */}
                <MyButton source="id" label="Ver Pedido" />
            </MyDatagrid>
        </List>
    )
}

export const AssistanceOrderEdit = (props) => {
    const location = useLocation()

    const redirect = (basePath, id, data) =>
        `/admin/assistanceOrders/${data.id}/show`

    const transform = (data) => ({
        ...data,
        type: location.state.type,
    })

    const validateDates = (values) => {
        const errors = {}
        const checkIn = new Date(values.checkInEdit)
        const checkOut = new Date(values.checkOutEdit)

        if (checkOut <= checkIn) {
            errors.checkOutEdit =
                'A data de saída deve ser maior que a data de entrada'
        }

        return errors
    }

    if (
        location.state &&
        location.state.type &&
        location.state.type === 'dates'
    )
        return (
            <Edit
                title="Alterar período da reserva"
                {...props}
                mutationMode="pessimistic"
                transform={transform}
            >
                <SimpleForm redirect={redirect} validate={validateDates}>
                    <DateInput
                        label="Entrada"
                        source="checkInEdit"
                        inputProps={{
                            min: formatXmlDate(new Date()),
                        }}
                    />
                    <DateInput
                        label="Saída"
                        source="checkOutEdit"
                        inputProps={{
                            min: formatXmlDate(new Date()),
                        }}
                    />
                </SimpleForm>
            </Edit>
        )
    else return null
}

export const AssistanceOrderShow = ({ permissions, ...props }) => {
    const { record } = useShowController(props)

    const checkAuth = useCheckAuth()
    const handleCheckAuth = () => checkAuth()
    const notify = useNotify()
    const refresh = useRefresh()

    const classes = useStyles()

    const [value, setValue] = React.useState(0)
    const [emailVoucher, setEmailVoucher] = React.useState(null)
    const [comment, setComment] = React.useState(null)
    const [commentModalOpen, setCommentModalOpen] = React.useState(false)
    const [sendVoucherModalOpen, setSendVoucherModalOpen] =
        React.useState(false)
    const [forceBookingModalOpen, setforceBookingModalOpen] =
        React.useState(false)
    const [cancelVoucherModalOpen, setCancelVoucherModalOpen] =
        React.useState(false)
    const [voucherToCancel, setVoucherToCancel] = React.useState(null)
    const [cancelOrderModalOpen, setCancelOrderModalOpen] =
        React.useState(false)
    const [cancelPartialOrderModalOpen, setCancelPartialOrderModalOpen] =
        React.useState(false)
    const [orderToCancel, setOrderToCancel] = React.useState(null)
    const [removeOrderModalOpen, setRemoveOrderModalOpen] =
        React.useState(false)
    const [orderToRemove, setOrderToRemove] = React.useState(null)
    const [forceCancelModalOpen, setforceCancelModalOpen] =
        React.useState(false)
    const [forceCancelPartialModalOpen, setforceCancelPartialModalOpen] =
        React.useState(false)
    const [removeTagModalOpen, setRemoveTagModalOpen] = React.useState(false)
    const [tagToRemove, setTagToRemove] = React.useState(null)
    const [sendGateModalOpen, setSendGateModalOpen] = React.useState(false)
    // const [orderToGate, setOrderToGate] = React.useState(null);
    const [newTag, setNewTag] = React.useState('')
    const [openNewTagDialog, setOpenNewTagDialog] = React.useState(false)
    const [tagOptions, setTagOptions] = React.useState([])
    const [users, setUserOptions] = React.useState([])
    const [openNewAgentDialog, setOpenNewAgentDialog] = React.useState(false)
    const [newAgent, setNewAgent] = React.useState('')
    const [paymentModalOpen, setPaymentModalOpen] = React.useState(false)
    const [newPayment, setNewPayment] = React.useState('')
    const [gateXml, setGateXml] = React.useState('')
    const [imgFile, setImgFile] = React.useState(null)
    const [paymentId, setPaymentId] = React.useState()

    const handleFile = (event) => {
        if (!event.target.files) return

        const productImage = event.target.files[0]

        if (!productImage) return

        if (
            productImage.type === 'image/png' ||
            productImage.type === 'image/jpeg' ||
            productImage.type === 'application/pdf'
        ) {
            setImgFile(productImage)
        } else {
            notify(
                'Formato de arquivo não suportado, tente os tipos png,jpeg ou pdf',
                'warning'
            )
            refresh()
        }
    }

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result)
            }
            fileReader.onerror = (error) => {
                reject(error)
            }
        })
    }

    const uploadAttachment = async (orderId) => {
        if (!imgFile) return

        const base64 = await convertToBase64(imgFile)

        try {
            await handleCheckAuth()
            const { data: uploadAttachmentData } = await api.post(
                '/admin/uploads/attachment',
                {
                    imageFile64: base64,
                    imageType: imgFile.type,
                    imageName: imgFile.name,
                }
            )

            const path = uploadAttachmentData.data.path

            const { data: getAttachmentData } = await api.post(
                '/admin/uploads/getAttachment',
                { path }
            )

            const attachment = getAttachmentData.data.url

            const { data: updateAttachmentData } = await api.put(
                `/admin/payment/${orderId}`,
                { attachment }
            )

            if (
                uploadAttachmentData.success &&
                getAttachmentData.success &&
                updateAttachmentData.success
            ) {
                notify('Upload do comprovante com sucesso.!', 'success')
                refresh()
            } else {
                notify('Erro ao fazer upload do comprovante', 'warning')
                refresh()
            }
        } catch (error) {
            console.log('Erro ao fazer upload do comprovante')
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`nav-tabpanel-${index}`}
                aria-labelledby={`nav-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        )
    }

    const sendVoucher = async (orderId, email) => {
        try {
            await handleCheckAuth()
            const result = await api.post(`/voucher/${orderId}`, {
                email: email,
            })
            if (result.data.success) {
                notify('Voucher enviado com sucesso!', 'success')
                refresh()
            } else throw new Error()
        } catch (error) {
            console.log('erro ao reenviar voucher', error)
            notify('Erro ao enviar voucher!', 'warning')
            refresh()
        }
    }

    const addComment = async (orderId, comment) => {
        try {
            await handleCheckAuth()
            const result = await api.post(`/admin/orderComments`, {
                orderId,
                comment,
            })
            if (result.data.success) {
                notify('Voucher enviado com sucesso!', 'success')
                refresh()
                setComment('')
            } else throw new Error()
        } catch (error) {
            console.log('erro ao reenviar comentário', error)
            notify('Erro ao enviar comentário!', 'warning')
            refresh()
        }
    }

    const cancelVoucher = async () => {
        let errorMessage = null
        try {
            await handleCheckAuth()
            const result = await api.post('/assistance/voucher/cancel', {
                voucher: voucherToCancel.voucher,
                insurer: voucherToCancel.insurer,
                cpf: voucherToCancel.cpf,
                orderId: voucherToCancel.orderId,
            })
            if (result.data.success) {
                notify('Voucher cancelado com sucesso!', 'success')
                refresh()
            } else {
                if (result.data.message) errorMessage = result.data.message
                notify(
                    `Erro ao cancelar voucher: ${errorMessage ? errorMessage : ''
                    }`,
                    'warning'
                )
                refresh()
            }
        } catch (error) {
            console.log('erro ao cancelar voucher')
            notify(
                `Erro ao cancelar voucher! ${errorMessage ? errorMessage : ''}`,
                'warning'
            )
            refresh()
        }
    }

    const cancelOrder = async () => {
        let errorMessage = null
        try {
            await handleCheckAuth()
            const result = await api.post(
                `/assistance/cancel/${orderToCancel.orderId}`,
                { cancelType: 1 }
            )
            if (result.data.success) {
                notify('Pedido cancelado com sucesso!', 'success')
                refresh()
            } else {
                if (result.data.message) errorMessage = result.data.message
                notify(
                    `Erro ao cancelar pedido: ${errorMessage ? errorMessage : ''
                    }`,
                    'warning'
                )
                refresh()
            }
        } catch (error) {
            console.log('erro ao cancelar pedido')
            notify(
                `Erro ao cancelar pedido! ${errorMessage ? errorMessage : ''}`,
                'warning'
            )
            refresh()
        }
    }

    const cancelPartialOrder = async () => {
        let errorMessage = null
        try {
            await handleCheckAuth()
            const result = await api.post(
                `/assistance/cancel/${orderToCancel.orderId}`,
                { cancelType: 2 }
            )
            if (result.data.success) {
                notify('Pedido cancelado parcialmente com sucesso!', 'success')
                refresh()
            } else {
                if (result.data.message) errorMessage = result.data.message
                notify(
                    `Erro ao cancelar pedido parcialmente: ${errorMessage ? errorMessage : ''
                    }`,
                    'warning'
                )
                refresh()
            }
        } catch (error) {
            console.log('erro ao cancelar pedido parcial')
            notify(
                `Erro ao cancelar pedido parcialmente! ${errorMessage ? errorMessage : ''}`,
                'warning'
            )
            refresh()
        }
    }

    const removeOrder = async () => {
        let errorMessage = null
        try {
            await handleCheckAuth()
            const result = await api.post(
                `/assistance/remove/${orderToRemove.orderId}`,
                { orderId: orderToRemove.orderId }
            )
            if (result.data.success) {
                notify('Pedido removido com sucesso!', 'success')
                refresh()
            } else {
                if (result.data.message) errorMessage = result.data.message
                notify(
                    `Erro ao remover pedido: ${errorMessage ? errorMessage : ''
                    }`,
                    'warning'
                )
                refresh()
            }
        } catch (error) {
            console.log('erro ao remover pedido')
            notify(
                `Erro ao remover pedido! ${errorMessage ? errorMessage : ''}`,
                'warning'
            )
            refresh()
        }
    }

    const approveManually = async (orderId, paymentId) => {
        try {
            await handleCheckAuth()
            const result = await api.post('/approveManually', { orderId, paymentId })
            if (result.data.success) {
                notify('Reserva aprovada manualmente!', 'success')
                refresh()
            } else return new Error(result?.data?.message)
        } catch (error) {
            console.log('erro ao aprovar manualmente', error)
            notify(
                `Erro ao aprovar manualmente a reserva! ${error?.response?.data?.message}`,
                'error'
            )
            refresh()
        }
    }

    const cancelManually = async (orderId) => {
        try {
            await handleCheckAuth()
            const result = await api.post('/cancelManually', { orderId, cancelType: 1 })
            if (result.data.success) {
                notify('Reserva cancelada manualmente!', 'success')
                refresh()
            } else throw new Error()
        } catch (error) {
            console.log('erro ao cancelada manualmente', error)
            notify(
                `Erro ao cancelada manualmente a reserva! ${JSON.stringify(
                    error
                )}`,
                'warning'
            )
            refresh()
        }
    }

    const cancelPartialManually = async (orderId) => {
        try {
            await handleCheckAuth()
            const result = await api.post('/cancelManually', { orderId, cancelType: 2 })
            if (result.data.success) {
                notify('Reserva cancelada manualmente de forma parcial!', 'success')
                refresh()
            } else throw new Error()
        } catch (error) {
            console.log('erro ao cancelar manualmente de forma parcial', error)
            notify(
                `Erro ao cancelar manualmente de forma parcial! ${JSON.stringify(
                    error
                )}`,
                'warning'
            )
            refresh()
        }
    }

    const sendGate = async (orderId) => {
        try {
            await handleCheckAuth()
            const result = await api.post(`/xml/${orderId}`, {
                // email: email,
            })
            if (result.data.success) {
                notify('Enviado para Gate com sucesso!', 'success')
                refresh()
            } else throw new Error()
        } catch (error) {
            console.log('erro ao enviar para Gate', error)
            notify('Erro ao enviar para Gate!', 'warning')
            refresh()
        }
    }

    const forceBookingModal = async (paymentId) => {
        setPaymentId(paymentId)
        setforceBookingModalOpen(true)
    }

    const forceCancelModal = async () => {
        setforceCancelModalOpen(true)
    }

    const forceCancelPartialModal = async () => {
        setforceCancelPartialModalOpen(true)
    }

    const sendVoucherModal = async () => {
        setSendVoucherModalOpen(true)
    }

    const sendGateModal = async () => {
        setSendGateModalOpen(true)
    }

    const cancelVoucherModal = async (voucher, insurer, orderId) => {
        if (voucher && insurer) {
            setVoucherToCancel({ voucher, insurer, orderId })
            setCancelVoucherModalOpen(true)
        } else {
            console.log('reseva sem voucher emitido ou segurador')
            notify('Reseva sem voucher emitido ou segurador', 'warning')
        }
    }

    const cancelOrderModal = async (orderId) => {
        if (orderId) {
            setOrderToCancel({ orderId })
            setCancelOrderModalOpen(true)
        } else {
            console.log('reseva sem orderId')
            notify('Reseva sem orderId', 'warning')
        }
    }

    const cancelPartialOrderModal = async (orderId) => {
        if (orderId) {
            setOrderToCancel({ orderId })
            setCancelPartialOrderModalOpen(true)
        } else {
            console.log('reseva sem orderId')
            notify('Reseva sem orderId', 'warning')
        }
    }

    const removeOrderModal = async (orderId) => {
        if (orderId) {
            setOrderToRemove({ orderId })
            setRemoveOrderModalOpen(true)
        } else {
            console.log('reseva sem orderId')
            notify('Reseva sem orderId', 'warning')
        }
    }

    const handleNewTag = async (productTags) => {
        try {
            const result = await api.get(`admin/labels`)
            productTags = productTags.map((a) => a.labelId)
            const tags = result.data.filter((a) => !productTags.includes(a.id))
            setTagOptions(tags)
            setNewTag('')
            setOpenNewTagDialog(true)
        } catch (error) {
            console.log('Erro ao consultar tags', error)
        }
    }

    const newProductTag = async (orderId) => {
        try {
            if (orderId && newTag) {
                await handleCheckAuth()
                await api.post(`admin/orderLabels`, {
                    orderId,
                    labelId: newTag,
                })
                setOpenNewTagDialog(false)
                refresh()
            } else {
                notify(`Selecione uma tag`, { type: 'error' })
            }
        } catch (error) {
            console.log('erro ao adicionar tag')
        }
    }

    const handleChangeNewTag = (value) => {
        setNewTag(value)
    }

    const removeTagModal = async (orderLabelId) => {
        if (orderLabelId) {
            setTagToRemove({ orderLabelId })
            setRemoveTagModalOpen(true)
        } else {
            console.log('tag sem id')
            notify('Tag sem id', 'warning')
        }
    }

    const removeTag = async () => {
        let errorMessage = null
        try {
            await handleCheckAuth()
            const result = await api.delete(
                `/admin/orderLabels/${tagToRemove.orderLabelId}`,
                {}
            )
            if (result.data) {
                notify('Label cancelada com sucesso!', 'success')
                refresh()
            } else {
                if (result.data.message) errorMessage = result.data.message
                notify(
                    `Erro ao cancelar label: ${errorMessage ? errorMessage : ''
                    }`,
                    'warning'
                )
                refresh()
            }
        } catch (error) {
            console.log('erro ao cancelar label')
            notify(
                `Erro ao cancelar label! ${errorMessage ? errorMessage : ''}`,
                'warning'
            )
            refresh()
        }
    }

    const handleChangeNewAgent = (value) => {
        setNewAgent(value)
    }

    const newAgentOrder = async (orderId) => {
        try {
            if (orderId && newAgent) {
                await handleCheckAuth()
                await api.put(`admin/assistanceOrders/${orderId}/agent`, {
                    orderId,
                    agentId: newAgent,
                })
                setOpenNewAgentDialog(false)
                refresh()
            } else {
                notify(`Selecione um agente`, { type: 'error' })
            }
        } catch (error) {
            console.log('erro ao adicionar agente')
        }
    }

    const handleChangeNewPayment = (value) => {
        setNewPayment(value)
    }

    const addPayment = async (orderId, newPayment) => {
        try {
            await handleCheckAuth()
            if (!orderId || !newPayment) {
                notify(`Oops! Insira uma forma de pagamento!`, 'warning')
                return false
            }
            const result = await api.post('/paymentManually', {
                orderId,
                paymentType: newPayment,
            })
            if (result.data.success) {
                notify('Novo Pagamento Inserido!', 'success')
                refresh()
                if (['Manual', 'Invoice'].includes(newPayment)) {
                    setforceBookingModalOpen(true)
                }
            } else throw new Error()
        } catch (error) {
            console.log('erro ao criar Pagamento manualmente', error)
            notify(
                `Erro ao criar Pagamento manualmente! ${JSON.stringify(error)}`,
                'warning'
            )
            refresh()
        }
    }

    const getXmlGate = async (orderId) => {
        try {
            await handleCheckAuth()
            const { data } = await api.get(`/xml/preview/${orderId}`)
            setGateXml(data)
        } catch (error) {
            console.log(error)
            notify('Erro ao recuperar xml do gate!', 'warning')
            refresh()
        }
    }

    const changeBackgroundByPaymentStatus = (paymentStatus) => {
        const backgroundStatus = {
            'Pré-aprovado': "#fff2d4",
            'Capturado': "#e2ffe7",
            "Cancelado": "#ffeee4",
            "Erro Pré-Aprovar": "#ffeee4",
            "Erro Capturar": "#ffeee4",
            "Aprovado Manualmente": "#e2ffe7",
        }

        return backgroundStatus[paymentStatus]
    }

    const getPaymeeUrl = (url) => {
        if (!url) return 'https://secure.paymee.com.br'

        if (url.includes('apisandbox.paymee')) {
            return 'https://apisandbox.paymee.com.br'
        }

        return 'https://secure.paymee.com.br'

    }

    useEffect(() => {
        ; (async () => {
            try {
                const result = await api.get(
                    `admin/users?_end=100&_order=ASC&_sort=email&_start=0&role=assistanceuser`
                )
                setUserOptions(result.data)
                console.log(users)
            } catch (error) {
                console.log('Erro ao consultar users', error)
            }
        })()
    }, [])

    const calculateRefund = (value) => {
        const REFUND_PERCENT_VALUE = 20

        const refundTotal = (value * REFUND_PERCENT_VALUE) / 100

        return `R$ ${Number(value - refundTotal).toFixed(2)}`
    }

    if (record) {
        return (
            <div className={classes.root}>
                <TopToolbar className={classes.actions}>
                    <BackButton />
                    {/* ***** */}
                    {/* Aprovar Manualmente: apenas se Pendente/Erro Reserva*/}
                    {8 === record.status &&
                        permissions.includes('assistanceadmin') && (
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ backgroundColor: 'Green' }}
                                onClick={() => forceBookingModal()}
                            >
                                {8 === record.status &&
                                    'Emitir Vouchers'}
                            </Button>
                        )}
                    {/* Cancelar Pedido: apenas se  Aguardando Pagamento */}
                    {[11].includes(record.status) &&
                        permissions.includes('assistanceadmin') && (
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ backgroundColor: 'Red' }}
                                onClick={() => cancelOrderModal(record.id)}
                            >
                                Cancelar Pedido
                            </Button>
                        )}
                    {/* Reenviar Voucher: só permite reenviar voucher e cancelar se o pedido estiver reservado ou concluído */}
                    {[4, 5].includes(record.status) && (
                        <>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => sendVoucherModal()}
                            >
                                Reenviar Voucher
                            </Button>
                        </>
                    )}
                    {/* Estonardo Manualmente: apenas se Cancelado Erro Estorno ou cancelado Erro Voucher */}
                    {[12, 13].includes(record.status) &&
                        permissions.includes('assistanceadmin') && (
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ backgroundColor: 'Red' }}
                                onClick={() => forceCancelModal()}
                            >
                                {record.status === 12
                                    ? 'ESTORNADO MANUALMENTE'
                                    : 'VOUCHER CANCELADO MANUALMENTE + ESTORNAR INTEGRAL'}
                            </Button>
                        )}
                    {[12, 13].includes(record.status) &&
                        permissions.includes('assistanceadmin') && (
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ backgroundColor: 'Tomato' }}
                                onClick={() => forceCancelPartialModal()}
                            >
                                {record.status === 12
                                    ? 'ESTORNADO MANUALMENTE PARCIAL'
                                    : 'VOUCHER CANCELADO MANUALMENTE + ESTORNAR PARCIAL'}
                            </Button>
                        )}
                </TopToolbar>
                <AppBar position="static">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="simple tabs example"
                    >
                        <Tab label="Informações" id="info" />
                        <Tab
                            label={`Histórico (${record.OrderHistories?.length})`}
                            id="history"
                        />
                        <Tab
                            label={`Comentários (${record.Comments?.length})`}
                            id="comments"
                        />
                        <Tab
                            label={'Gate xml'}
                            id="gateXml"
                            onClick={() => getXmlGate(record.id)}
                        />
                    </Tabs>
                </AppBar>
                {/* Resumo */}
                <TabPanel value={value} index={0}>
                    {/* Alertas */}
                    <Paper>
                        {/* </TopToolbar> */}
                        {((Number(record.status) === 7 &&
                            Number(record.Payments[0].status) === 6) ||
                            (Number(record.status) === 4 &&
                                Number(record.Payments[0].status) === 3) ||
                            (Number(record.status) === 8 &&
                                [2, 3, 6].includes(
                                    Number(record.Payments[0].status)
                                ) &&
                                ['CreditCard', 'Invoice'].includes(
                                    record.Payments[0].type
                                )) ||
                            (Number(record.status) === 8 &&
                                [3].includes(
                                    Number(record.Payments[0].status)
                                ) &&
                                record.Payments[0].type !== 'CreditCard') ||
                            [12, 13].includes(Number(record.status)) ||
                            ((Number(record.status) === 1 ||
                                Number(record.status) === 11) &&
                                (Number(record.Payments[0].status) === 1 ||
                                    Number(record.Payments[0].status) === 2) &&
                                (record.Payments[0].type !== 'Transfer' ||
                                    record.Payments[0].type !== 'Slip' ||
                                    (record.Payments[0].type === 'Slip' &&
                                        (new Date(
                                            new Date().getTime() -
                                            3 * 60 * 60 * 1000
                                        ).getTime() -
                                            new Date(
                                                record.Payments[0].createdAt
                                            ).getTime()) /
                                        (24 * 60 * 60 * 1000) <=
                                        2) ||
                                    (record.Payments[0].type === 'Transfer' &&
                                        (new Date(
                                            new Date().getTime() -
                                            3 * 60 * 60 * 1000
                                        ).getTime() -
                                            new Date(
                                                record.Payments[0].createdAt
                                            ).getTime()) /
                                        (24 * 60 * 60 * 1000) <=
                                        1)))) && (
                                <Grid container spacing={3}>
                                    <Grid item sm={12} md={12}>
                                        Pendência:
                                        {Number(record.status) === 7 &&
                                            Number(record.Payments[0].status) === 6
                                            ? ' Apólice emitida, porém cartão de crédito não foi capturado. Forçar captura novamente!'
                                            : ''}
                                        {Number(record.status) === 4 &&
                                            Number(record.Payments[0].status) === 3
                                            ? ' Apólice emitida, porém Pedido não está como Concluído, verificar pendências e concluir.'
                                            : ''}
                                        {Number(record.status) === 8 &&
                                            [2, 3, 6].includes(
                                                Number(record.Payments[0].status)
                                            ) &&
                                            ['CreditCard', 'Invoice'].includes(
                                                record.Payments[0].type
                                            )
                                            ? 'Erros na emissão e/ou captura do pagamento'
                                            : ''}
                                        {Number(record.status) === 8 &&
                                            [3].includes(
                                                Number(record.Payments[0].status)
                                            ) &&
                                            record.Payments[0].type !== 'CreditCard'
                                            ? ' Erro na emissão da apólice, tentar novamente'
                                            : ''}
                                        {[12, 13].includes(Number(record.status))
                                            ? 'Realizar o estorno do pgto e/ou realizar cancelamento manual do voucher'
                                            : ''}
                                        {(Number(record.status) === 1 ||
                                            Number(record.status) === 11) &&
                                            (Number(record.Payments[0].status) === 1 ||
                                                Number(record.Payments[0].status) ===
                                                2) &&
                                            (record.Payments[0].type !== 'Transfer' ||
                                                record.Payments[0].type !== 'Slip' ||
                                                (record.Payments[0].type !== 'Slip' &&
                                                    (new Date(
                                                        new Date().getTime() -
                                                        3 * 60 * 60 * 1000
                                                    ).getTime() -
                                                        new Date(
                                                            record.Payments[0].createdAt
                                                        ).getTime()) /
                                                    (24 * 60 * 60 * 1000) <=
                                                    2) ||
                                                (record.Payments[0].type !==
                                                    'Transfer' &&
                                                    (new Date(
                                                        new Date().getTime() -
                                                        3 * 60 * 60 * 1000
                                                    ).getTime() -
                                                        new Date(
                                                            record.Payments[0].createdAt
                                                        ).getTime()) /
                                                    (24 * 60 * 60 * 1000) <=
                                                    1))
                                            ? ' Verificar se pagamento foi capturado/confirmado nos gateways (webhook não recebido até o momento). Caso positivo, emitir manualmente'
                                            : ''}
                                    </Grid>
                                </Grid>
                            )}
                    </Paper>
                    {/* Labels */}
                    <Paper>
                        <Grid container spacing={3}>
                            {record.OrderLabels &&
                                record.OrderLabels.map((item) => {
                                    return (
                                        <Grid item sm={2} md={2}>
                                            <Button
                                                InputProps={{ readOnly: true }}
                                                size="small"
                                                variant="outlined"
                                                style={{
                                                    width: 200,
                                                    borderRadius: 16,
                                                    backgroundColor:
                                                        item.Label
                                                            .backgroundColor,
                                                    font: item.Label.fontColor,
                                                }}
                                            >
                                                {item.Label.name}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                style={{
                                                    width: 200,
                                                    backgroundColor: 'Red',
                                                }}
                                                onClick={() =>
                                                    removeTagModal(item.id)
                                                }
                                            >
                                                X
                                            </Button>
                                        </Grid>
                                    )
                                })}
                            <Grid item sm={2} md={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    className={classes.button}
                                    onClick={() =>
                                        handleNewTag(record.OrderLabels)
                                    }
                                    style={{ backgroundColor: 'Green' }}
                                >
                                    Adicionar Tag
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                    {/* Informações do Pedido */}
                    <Paper>
                        <h4>Informações do Pedido</h4>
                        <Grid container spacing={3}>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    id="id"
                                    label="Pedido"
                                    value={record.id}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    InputProps={{
                                        readOnly: true,
                                        className:
                                            record.status === 5
                                                ? classes.successInput
                                                : record.status === 12 &&
                                                    record.Payments[0].type ===
                                                    'Invoice' &&
                                                    classes.warningInput
                                                    ? record.status === 12 &&
                                                    record.Payments[0].type ===
                                                    'Invoice' &&
                                                    classes.warningInput
                                                    : [
                                                        6, 7, 8, 9, 10, 12, 13,
                                                        14,
                                                    ].includes(record.status) &&
                                                    classes.statusInput,
                                    }}
                                    id="statusText"
                                    label="Situação"
                                    value={record.statusText}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    id="createdAt"
                                    label="createdAt"
                                    value={record.createdAt}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    id="reservationId"
                                    label="Processo"
                                    value={record.reservationId}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    id="fraudPreventionStatusText"
                                    label="Anti Fraude"
                                    value={record.fraudPreventionStatusText}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    id="coupon"
                                    label="Cupom de Desconto"
                                    value={record.coupon}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    id="originUrl"
                                    label="URL Origem"
                                    value={record.originUrl}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    id="previousUrl"
                                    label="URL Final"
                                    value={record.previousUrl}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    id="extraRequests"
                                    label="Solicitação Adicional"
                                    value={record.extraRequests}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    id="observation"
                                    label="Observações"
                                    value={record.observation}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <h4>Preços</h4>
                        <Grid container spacing={3}>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    id="grossOrder"
                                    label="Valor Bruto"
                                    value={formatter.format(record.gross || 0)}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    id="discountOrder"
                                    label="(-) Desconto"
                                    value={formatter.format(
                                        record.discount || 0
                                    )}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    id="totalOrder"
                                    label="(=) Valor Final"
                                    value={formatter.format(record.total || 0)}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    id="costOrder"
                                    label="(-) Custo"
                                    value={formatter.format(record.cost || 0)}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    id="marginOrder"
                                    label="(=) Margem"
                                    value={formatter.format(record.margin || 0)}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                    {/* Produto */}
                    <Paper>
                        <h4>
                            Informações do Produto -{' '}
                            <a
                                target="_blank"
                                href={
                                    'https://dash.melhorseguro.com.br/#/admin/products/' +
                                    (record.productId || record.planId) +
                                    '/show'
                                }
                                rel="noopener noreferrer"
                            >
                                Produto
                            </a>
                        </h4>
                        <Grid container spacing={3}>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    id="insurerName"
                                    label="Seguradora"
                                    value={
                                        record.Insurer
                                            ? record.Insurer.name
                                            : ''
                                    }
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    id="productName"
                                    label="productName"
                                    value={
                                        record.productName
                                            ? record.productName
                                            : ''
                                    }
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    id="productCode"
                                    label="productCode"
                                    value={
                                        record.productCode
                                            ? record.productCode
                                            : ''
                                    }
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item sm={12} md={2}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    InputLabelProps={{ shrink: true }}
                                    id="checkIn"
                                    label="Início"
                                    value={record.checkIn}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={12} md={2}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    InputLabelProps={{ shrink: true }}
                                    id="checkOut"
                                    label="Fim"
                                    value={record.checkOut}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={12} md={2}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    InputLabelProps={{ shrink: true }}
                                    id="checkOut"
                                    label="Diárias"
                                    value={record.daily}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={12} md={2}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    id="destination"
                                    label="destination"
                                    value={
                                        record.destination
                                            ? record.destination
                                            : ''
                                    }
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    id="fareCode"
                                    label="fareCode"
                                    value={
                                        record.fareCode ? record.fareCode : ''
                                    }
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                    {/* Pagamentos */}
                    <Paper>
                        <h4>
                            Pagamentos
                            {![5, 9, 12, 13, 14].includes(record.status) &&
                                permissions.includes('assistanceadmin') && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        className={classes.button}
                                        onClick={() =>
                                            setPaymentModalOpen(true)
                                        }
                                        style={{
                                            backgroundColor: 'Green',
                                            marginLeft: 15,
                                        }}
                                    >
                                        Adicionar Pagamento
                                    </Button>
                                )}
                        </h4>
                        {record.Payments &&
                            record.Payments.map((payment, index) => {
                                switch (payment.type) {
                                    case 'CreditCard':
                                        return (
                                            <Paper>
                                                <h4>Cartão {index + 1}</h4>
                                                <Grid container spacing={3}>
                                                    <Grid item sm={12} md={2}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            id="cardHolder"
                                                            label="Pagador"
                                                            value={
                                                                payment.cardHolder
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12} md={4}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            id="cardNumber"
                                                            label="Cartão"
                                                            value={
                                                                payment.cardNumber
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12} md={2}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            id="statusText"
                                                            label="Situação"
                                                            value={
                                                                payment.statusText
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                            style={{
                                                                backgroundColor: changeBackgroundByPaymentStatus(payment.statusText)
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12} md={2}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: false,
                                                            }}
                                                            id="createdAt"
                                                            label="createdAt"
                                                            value={
                                                                payment.createdAt
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12} md={2}>
                                                        {[6, 8, 11].includes(record.status)
                                                            && permissions.includes('assistanceadmin') && (
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    style={{ backgroundColor: 'Green' }}
                                                                    onClick={() => forceBookingModal(payment.id)}
                                                                >
                                                                    {[6, 11].includes(record.status) && ("Aprovar Manualmente")}
                                                                </Button>
                                                            )}
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={3}>
                                                    <Grid item sm={12} md={2}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            id="cardBrand"
                                                            label="Bandeira"
                                                            value={
                                                                payment.cardBrand
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12} md={2}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            id="cardValue"
                                                            label="Valor"
                                                            value={formatter.format(
                                                                payment.value
                                                            )}
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    {payment.status == 10 && (
                                                        <Grid item sm={12} md={2}>
                                                            <TextField
                                                                InputProps={{
                                                                    readOnly: true,
                                                                }}
                                                                id="refundValue"
                                                                label="Valor Estornado"
                                                                value={
                                                                    calculateRefund(payment.value)
                                                                }
                                                                variant="outlined"
                                                                size="small"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                    )}
                                                    <Grid item sm={12} md={2}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            id="installments"
                                                            label="Parcelas"
                                                            value={`${payment.installments}x`}
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12} md={2}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            id="paymentId"
                                                            label="ID Pagamento Cielo"
                                                            value={
                                                                payment.cardPaymentId
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12} md={2}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            id="cardAuthorization"
                                                            label="Autorização"
                                                            value={
                                                                payment.cardAuthorization
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        )
                                    case 'Invoice':
                                        return (
                                            <Paper>
                                                <h4>Faturado</h4>
                                                <Grid container spacing={3}>
                                                    <Grid item sm={12} md={2}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            id="cardValue"
                                                            label="Valor"
                                                            value={formatter.format(
                                                                payment.value
                                                            )}
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12} md={7}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            id="slipStatus"
                                                            label="Situação"
                                                            value={
                                                                payment.statusText
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                            style={{
                                                                backgroundColor: changeBackgroundByPaymentStatus(payment.statusText)
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12} md={3}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: false,
                                                            }}
                                                            id="createdAt"
                                                            label="createdAt"
                                                            value={
                                                                payment.createdAt
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        )
                                    case 'Transfer':
                                        return (
                                            <Paper>
                                                <h4>
                                                    Transferência/Pix -{' '}
                                                    <a
                                                        target="_blank"
                                                        href={
                                                            'https://www2.paymee.com.br/merchants/Transaction/' +
                                                            payment.transferUuid
                                                        }
                                                        rel="noopener noreferrer"
                                                    >
                                                        PayMee
                                                    </a>
                                                </h4>
                                                <Grid container spacing={3}>
                                                    <Grid item sm={12} md={2}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            id="cardValue"
                                                            label="Valor"
                                                            value={formatter.format(
                                                                payment.value
                                                            )}
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    {payment.status == 10 && (
                                                        <Grid item sm={12} md={2}>
                                                            <TextField
                                                                InputProps={{
                                                                    readOnly: true,
                                                                }}
                                                                id="refundValue"
                                                                label="Valor Estornado"
                                                                value={
                                                                    calculateRefund(payment.value)
                                                                }
                                                                variant="outlined"
                                                                size="small"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                    )}
                                                    <Grid item sm={12} md={2}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            id="transferMethod"
                                                            label="Método"
                                                            value={
                                                                payment.transferMethod
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12} md={2}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            id="slipStatus"
                                                            label="Situação"
                                                            value={
                                                                payment.statusText
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                            style={{
                                                                backgroundColor: changeBackgroundByPaymentStatus(payment.statusText)
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12} md={2}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: false,
                                                            }}
                                                            id="linkUrl"
                                                            label="URL do Link"
                                                            value={getPaymeeUrl(payment.pixImageUrl) + '/redir/' + payment.transferUuid}
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12} md={2}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: false,
                                                            }}
                                                            id="createdAt"
                                                            label="createdAt"
                                                            value={
                                                                payment.createdAt
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12} md={2}>
                                                        {[6, 8, 11].includes(record.status)
                                                            && permissions.includes('assistanceadmin') && (
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    style={{ backgroundColor: 'Green' }}
                                                                    onClick={() => forceBookingModal(payment.id)}
                                                                >
                                                                    {[6, 11].includes(record.status) && ("Aprovar Manualmente")}
                                                                </Button>
                                                            )}
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        )
                                    case 'Slip':
                                        return (
                                            <Paper>
                                                <h4>Boleto</h4>
                                                <Grid container spacing={3}>
                                                    <Grid item sm={12} md={2}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            id="cardValue"
                                                            label="Valor"
                                                            value={formatter.format(
                                                                payment.value
                                                            )}
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12} md={3}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            id="slipStatus"
                                                            label="Situação"
                                                            value={
                                                                payment.statusText
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                            style={{
                                                                backgroundColor: changeBackgroundByPaymentStatus(payment.statusText)
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12} md={4}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: false,
                                                            }}
                                                            id="slipUrl"
                                                            label="URL Boleto"
                                                            value={
                                                                payment.slipUrl
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12} md={3}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: false,
                                                            }}
                                                            id="createdAt"
                                                            label="createdAt"
                                                            value={
                                                                payment.createdAt
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12} md={2}>
                                                        {[6, 8, 11].includes(record.status)
                                                            && permissions.includes('assistanceadmin') && (
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    style={{ backgroundColor: 'Green' }}
                                                                    onClick={() => forceBookingModal(payment.id)}
                                                                >
                                                                    {[6, 11].includes(record.status) && ("Aprovar Manualmente")}
                                                                </Button>
                                                            )}
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        )
                                    case 'Link':
                                        return (
                                            <Paper>
                                                <h4>
                                                    Link de Pgto -{' '}
                                                    <a
                                                        target="_blank"
                                                        href={payment.linkUrl}
                                                        rel="noopener noreferrer"
                                                    >
                                                        Pagtur
                                                    </a>
                                                </h4>
                                                <Grid container spacing={3}>
                                                    <Grid item sm={12} md={2}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            id="cardValue"
                                                            label="Valor"
                                                            value={formatter.format(
                                                                payment.value
                                                            )}
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    {payment.status == 10 && (
                                                        <Grid item sm={12} md={2}>
                                                            <TextField
                                                                InputProps={{
                                                                    readOnly: true,
                                                                }}
                                                                id="refundValue"
                                                                label="Valor Estornado"
                                                                value={
                                                                    calculateRefund(payment.value)
                                                                }
                                                                variant="outlined"
                                                                size="small"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                    )}
                                                    <Grid item sm={12} md={3}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            id="slipStatus"
                                                            label="Situação"
                                                            value={
                                                                payment.statusText
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                            style={{
                                                                backgroundColor: changeBackgroundByPaymentStatus(payment.statusText)
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12} md={3}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: false,
                                                            }}
                                                            id="linkUrl"
                                                            label="URL do Link"
                                                            value={
                                                                payment.linkUrl
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12} md={2}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: false,
                                                            }}
                                                            id="createdAt"
                                                            label="createdAt"
                                                            value={payment.createdAt}
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12} md={2}>
                                                        {[6, 8, 11].includes(record.status)
                                                            && permissions.includes('assistanceadmin') && (
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    style={{ backgroundColor: 'Green' }}
                                                                    onClick={() => forceBookingModal(payment.id)}
                                                                >
                                                                    {[6, 11].includes(record.status) && ("Aprovar Manualmente")}
                                                                </Button>
                                                            )}
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        )
                                    case 'Manual':
                                        return (
                                            <Paper>
                                                <h4>Pagamento Manual</h4>
                                                <Grid container spacing={3}>
                                                    <Grid item sm={12} md={2}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            id="cardValue"
                                                            label="Valor"
                                                            value={formatter.format(
                                                                payment.value
                                                            )}
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12} md={3}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            id="slipStatus"
                                                            label="Situação"
                                                            value={payment.statusText}
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                            style={{
                                                                backgroundColor: changeBackgroundByPaymentStatus(payment.statusText)
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12} md={3}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: false,
                                                            }}
                                                            id="createdAt"
                                                            label="createdAt"
                                                            value={
                                                                payment.createdAt
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item sm={12} md={3}>
                                                        <TextField
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <ContentCopyIcon
                                                                        onClick={() =>
                                                                            navigator.clipboard.writeText(
                                                                                payment.attachment
                                                                            )
                                                                        }
                                                                        cursor={'pointer'}
                                                                    />
                                                                ),
                                                                readOnly: true,
                                                            }}
                                                            label="Url comprovante"
                                                            value={payment.attachment}
                                                            variant="outlined"
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        )
                                    default:
                                        return null
                                }
                            })}
                    </Paper>
                    {/* Usuário */}
                    {record.userId && record.userId > 0 && (
                        <Paper>
                            <h4>
                                Usuário -{' '}
                                <a
                                    target="_blank"
                                    href={
                                        'https://dash.melhorseguro.com.br/#/admin/users/' +
                                        record.userId
                                    }
                                    rel="noopener noreferrer"
                                >
                                    Perfil
                                </a>
                            </h4>
                            <Grid container spacing={3}>
                                <Grid item sm={12} md={4}>
                                    <TextField
                                        InputProps={{ readOnly: true }}
                                        id="userId"
                                        label="userId"
                                        value={record.userId}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <TextField
                                        InputProps={{ readOnly: true }}
                                        id="email"
                                        label="users.email"
                                        value={record.Users.email}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <TextField
                                        InputProps={{ readOnly: true }}
                                        id="gateId"
                                        label="gateId"
                                        value={record.gateId}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    )}
                    <Paper>
                        <h4>Agente Emissor</h4>
                        <Grid container spacing={3}>
                            <Grid item sm={12} md={4}>
                                <TextField
                                    InputProps={{ readOnly: true }}
                                    id="travelAgent"
                                    label="Agente"
                                    value={
                                        record.TravelAgent
                                            ? record.TravelAgent.email
                                            : ''
                                    }
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            {!record?.TravelAgent?.email && (
                                <Grid item sm={2} md={2}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        className={classes.button}
                                        onClick={() =>
                                            setOpenNewAgentDialog(true)
                                        }
                                        style={{ backgroundColor: 'Green' }}
                                    >
                                        Adicionar Agente
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </Paper>
                    {/* Responsável */}
                    <Paper>
                        <h4>Responsável / Pagador</h4>
                        <Paper>
                            <Grid container spacing={3}>
                                <Grid item sm={12} md={3}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        id="ownerName"
                                        label="Nome"
                                        value={record.ownerName}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item sm={12} md={3}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        id="ownerDob"
                                        label="Data Nascimento"
                                        value={formatDate(record.ownerDob)}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item sm={12} md={3}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        id="ownerPhone"
                                        label="Telefone"
                                        value={record.ownerPhone}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item sm={12} md={3}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        id="ownerEmail"
                                        label="Email"
                                        value={record.ownerEmail}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item sm={12} md={4}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        id="ownerAddress"
                                        label="Endereço"
                                        value={record.ownerAddress}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        id="ownerNumber"
                                        label="Número"
                                        value={record.ownerNumber}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        id="ownerComplement"
                                        label="Complemento"
                                        value={record.ownerComplement}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item sm={12} md={4}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        id="ownerDistrict"
                                        label="Bairro"
                                        value={record.ownerDistrict}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        id="ownerCity"
                                        label="Cidade"
                                        value={record.ownerCity}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item sm={12} md={2}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        id="ownerState"
                                        label="Estado"
                                        value={record.ownerState}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item sm={12} md={2}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        id="ownerCep"
                                        label="Cep"
                                        value={record.ownerCep}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                        <h4>Contato Emergência</h4>
                        <Paper>
                            <Grid container spacing={3}>
                                <Grid item sm={12} md={4}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        id="emergencyName"
                                        label="emergencyName"
                                        value={record.emergencyName}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        id="emergencyPhone"
                                        label="emergencyPhone"
                                        value={record.emergencyPhone}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item sm={12} md={4}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        id="emergencyCellphone"
                                        label="emergencyCellphone"
                                        value={record.emergencyCellphone}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Paper>
                    {/* Pessoa Juridica (empresa) */}
                    {record.isCompany && (
                        <Paper>
                            <h4>Empresa</h4>
                            <Paper>
                                <Grid container spacing={3}>
                                    <Grid item sm={12} md={4}>
                                        <TextField
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            id="companyName"
                                            label="Nome"
                                            value={record.companyName}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={4}>
                                        <TextField
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            id="gateId"
                                            label="gateId"
                                            value={record.gateId}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={4}>
                                        <TextField
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            id="observation"
                                            label="Observações"
                                            value={record.observation}
                                            variant="outlined"
                                            size="small"
                                            multiline
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Paper>
                    )}
                    {/* Segurados */}
                    <Paper>
                        <h4>Segurados / Vouchers</h4>
                        {/* Cancelar Vouchers */}
                        {record.Passengers &&
                            record.Passengers.length &&
                            permissions.includes('assistanceadmin') &&
                            [4, 5, 8, 10].includes(record.status) && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ backgroundColor: 'Red' }}
                                    onClick={() => cancelOrderModal(record.id)}
                                >
                                    Cancelar Todos Vouchers + Estorno Integral
                                </Button>
                            )}
                        {record.Passengers &&
                            record.Passengers.length &&
                            permissions.includes('assistanceadmin') &&
                            [4, 5, 8, 10].includes(record.status) && record.Payments[0].type !== 'Invoice' && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ backgroundColor: 'Tomato', marginLeft: '20px' }}
                                    onClick={() => cancelPartialOrderModal(record.id)}
                                >
                                    Cancelar Todos Vouchers + Estorno Parcial
                                </Button>
                            )}
                        {record.Passengers &&
                            record.Passengers.map((pax, paxIndex) => (
                                <Paper key={paxIndex}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'baseline',
                                        }}
                                    >
                                        <h4
                                            style={{
                                                margin: '0px 10px 0px 0px',
                                            }}
                                        >
                                            Segurado {paxIndex + 1}
                                        </h4>
                                        {/* {permissions.includes(
                                            'assistanceadmin'
                                        ) && (pax.voucher ? pax.voucher : undefined) && (pax.voucherStatus==='Emitido') && (record.Insurer ? record.Insurer : undefined) && (record.Passengers.length>1) && (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                style={{ backgroundColor: 'Red' }}
                                                onClick={() =>
                                                    cancelVoucherModal(
                                                        pax.voucher,
                                                        record.Insurer.code,
                                                        record.id
                                                    )
                                                }
                                            >
                                                Cancelar Voucher (#{paxIndex + 1})
                                            </Button>
                                        )} */}
                                    </div>
                                    <Grid container spacing={3}>
                                        <Grid item sm={12} md={4}>
                                            <TextField
                                                InputProps={{ readOnly: true }}
                                                id="owner"
                                                label="Responsável"
                                                value={pax.name}
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={4}>
                                            <TextField
                                                InputProps={{ readOnly: true }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                id="paxCpf"
                                                label="Cpf"
                                                value={pax.cpf}
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={4}>
                                            <TextField
                                                InputProps={{ readOnly: true }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                id="paxDob"
                                                label="Nascimento"
                                                value={formatDate(pax.dob)}
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item sm={12} md={3}>
                                            <TextField
                                                InputProps={{ readOnly: true }}
                                                id="voucher"
                                                label="Voucher"
                                                value={pax.voucher}
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={3}>
                                            <TextField
                                                InputProps={{ readOnly: true }}
                                                id="voucherStatus"
                                                label="voucherStatus"
                                                value={pax.voucherStatus}
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={3}>
                                            <TextField
                                                InputProps={{
                                                    endAdornment: (
                                                        <ContentCopyIcon
                                                            onClick={() =>
                                                                navigator.clipboard.writeText(
                                                                    pax.voucherUrl
                                                                )
                                                            }
                                                            cursor={'pointer'}
                                                        />
                                                    ),
                                                    readOnly: true,
                                                }}
                                                id="voucherUrlPt"
                                                label="VoucherUrlPt"
                                                value={pax.voucherUrl}
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={3}>
                                            <TextField
                                                InputProps={{
                                                    endAdornment: (
                                                        <ContentCopyIcon
                                                            onClick={() =>
                                                                navigator.clipboard.writeText(
                                                                    pax.voucherUrlEn
                                                                )
                                                            }
                                                            cursor={'pointer'}
                                                        />
                                                    ),
                                                    readOnly: true,
                                                }}
                                                id="voucherUrlEn"
                                                label="VoucherUrlEn"
                                                value={pax.voucherUrlEn}
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                        {/* <Grid item sm={12} md={3}>
                                        <TextField
                                            InputProps={{ readOnly: true }}
                                            InputLabelProps={{ shrink: true }}
                                            id="voucherUpgrade"
                                            label="Voucher (Upgrade)"
                                            value={pax.voucherUpgrade}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid> */}
                                        {pax.voucherUpgradeUrl > 0 && (
                                            <Grid item sm={12} md={2}>
                                                <form
                                                    action={
                                                        pax.voucherUpgradeUrl
                                                    }
                                                >
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        type="submit"
                                                        color="primary"
                                                        style={{
                                                            marginTop: '4px',
                                                        }}
                                                    >
                                                        Abrir Voucher
                                                    </Button>
                                                </form>
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Grid item sm={12} md={4}>
                                        <TextField
                                            InputProps={{ readOnly: true }}
                                            id="markup"
                                            label="markup R$"
                                            value={pax.markup}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                </Paper>
                            ))}
                    </Paper>
                    {/* Remover Pedidos */}
                    {[1, 2, 8, 9, 11].includes(record.status) &&
                        permissions.includes('sysadmin') && (
                            <Paper>
                                <h4>
                                    ATENÇÃO: Botão para remover pedidos de TESTE
                                </h4>
                                <p style={{ color: 'red' }}>
                                    Realizar e confirmar o cancelamento de
                                    voucher e estorno de pagamento antes de
                                    remover o pedido. Caso contrário,
                                    permanecerá uma venda ativa com status
                                    incorretos.
                                </p>
                                <p>
                                    Ao remover um pedido todos os pagamentos,
                                    vouchers e pedido mudarão para status
                                    "Removido"
                                </p>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{
                                        backgroundColor: 'Grey',
                                        textAlign: 'center',
                                    }}
                                    onClick={() => removeOrderModal(record.id)}
                                >
                                    Remover Pedido
                                </Button>
                            </Paper>
                        )}
                </TabPanel>
                {/* Histórico do Pedido */}
                <TabPanel value={value} index={1}>
                    <Paper>
                        <h4>Histórico do Pedido</h4>
                        {record.OrderHistories &&
                            record.OrderHistories.map((history, index) => (
                                <Grid key={index} container spacing={3}>
                                    <Grid item sm={12} md={6}>
                                        <TextField
                                            InputProps={{ readOnly: true }}
                                            id="action"
                                            label="Ação"
                                            value={history.action}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={2}>
                                        <TextField
                                            InputProps={{ readOnly: true }}
                                            id="user"
                                            label="Usuário"
                                            value={history.user}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={2}>
                                        <TextField
                                            InputProps={{ readOnly: true }}
                                            id="actionDate"
                                            label="Data"
                                            value={formatDateTime(
                                                history.createdAt
                                            )}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={2}>
                                        <TextField
                                            id="attachment"
                                            label="Anexo"
                                            value={history.attachment}
                                            InputProps={{
                                                endAdornment: (
                                                    <ContentCopyIcon
                                                        onClick={() =>
                                                            navigator.clipboard.writeText(
                                                                history.attachment
                                                            )
                                                        }
                                                        cursor={'pointer'}
                                                    />
                                                ),
                                                readOnly: true,
                                            }}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            ))}
                    </Paper>
                </TabPanel>
                <TopToolbar className={classes.actions}>
                    {/* Reenvio Gate: apenas se Concluído / Se a permissão do usuário for de sysadmin */}
                    {[5].includes(record.status) &&
                        permissions.includes('sysadmin') && (
                            <Button
                                variant="contained"
                                color="primary"
                                style={{
                                    backgroundColor: 'Red',
                                    right: '15px',
                                    position: 'absolute',
                                }}
                                onClick={() => sendGateModal()}
                            >
                                REPROCESSAR PEDIDO NO GATE
                            </Button>
                        )}
                </TopToolbar>
                {/* Comentarios */}
                <TabPanel value={value} index={2}>
                    <Paper>
                        <Grid container spacing={3}>
                            <Grid item sm={12} md={12}>
                                <h4>
                                    Comentários
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        className={classes.button}
                                        onClick={() =>
                                            setCommentModalOpen(true)
                                        }
                                        style={{
                                            backgroundColor: 'Green',
                                            marginLeft: 15,
                                        }}
                                    >
                                        Adicionar Comentário
                                    </Button>
                                </h4>
                            </Grid>
                        </Grid>
                        {record.Comments &&
                            record.Comments.map((comment, index) => (
                                <Grid key={index} container spacing={3}>
                                    <Grid item sm={12} md={8}>
                                        <TextField
                                            InputProps={{ readOnly: true }}
                                            id="comment"
                                            label="Comentário"
                                            value={comment.comment}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={2}>
                                        <TextField
                                            InputProps={{ readOnly: true }}
                                            id="user"
                                            label="Usuário"
                                            value={comment.user}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={2}>
                                        <TextField
                                            InputProps={{ readOnly: true }}
                                            id="actionDate"
                                            label="Data"
                                            value={formatDateTime(
                                                comment.createdAt
                                            )}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            ))}
                    </Paper>
                </TabPanel>
                {/* Gate xml */}
                <TabPanel value={value} index={3}>
                    <Paper>
                        <Grid container spacing={3}>
                            <Grid item sm={3} md={3}>
                                <div>{gateXml}</div>
                            </Grid>
                        </Grid>
                    </Paper>
                </TabPanel>

                <Dialog // aprovar manualmente
                    open={forceBookingModalOpen}
                    onClose={() => setforceBookingModalOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Tem certeza que deseja efetuar a reserva
                            manualmente? Esta ação irá mudar manualmente o
                            status do pagamento para Capturado e realizará a
                            emissão de todos os vouchers
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                approveManually(record.id, paymentId)
                                setforceBookingModalOpen(false)
                            }}
                            color="primary"
                        >
                            Sim
                        </Button>
                        <Button
                            onClick={() => setforceBookingModalOpen(false)}
                            color="primary"
                            autoFocus
                        >
                            Não
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog // cancelar manualmente integral
                    open={forceCancelModalOpen}
                    onClose={() => setforceCancelModalOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Tem certeza que deseja cancelar a reserva
                            manualmente? Esta ação irá mudar manualmente o
                            status do pagamento para Cancelado e realizará o
                            cancelamento de todos os vouchers
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                cancelManually(record.id)
                                setforceCancelModalOpen(false)
                            }}
                            color="primary"
                        >
                            Sim
                        </Button>
                        <Button
                            onClick={() => setforceCancelModalOpen(false)}
                            color="primary"
                            autoFocus
                        >
                            Não
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog // cancelar manualmente parcial
                    open={forceCancelPartialModalOpen}
                    onClose={() => setforceCancelPartialModalOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Tem certeza que deseja cancelar a reserva
                            manualmente de forma parcial? Esta ação irá mudar manualmente o
                            status do pagamento para Cancelado e realizará o
                            cancelamento de todos os vouchers
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                cancelPartialManually(record.id)
                                setforceCancelPartialModalOpen(false)
                            }}
                            color="primary"
                        >
                            Sim
                        </Button>
                        <Button
                            onClick={() => setforceCancelPartialModalOpen(false)}
                            color="primary"
                            autoFocus
                        >
                            Não
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog // cancelar voucher
                    open={cancelVoucherModalOpen}
                    onClose={() => setCancelVoucherModalOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Tem certeza que deseja cancelar este voucher? Esta
                            ação vai cancelar o voucher individualmente
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                cancelVoucher()
                                setCancelVoucherModalOpen(false)
                            }}
                            color="primary"
                        >
                            Sim
                        </Button>
                        <Button
                            onClick={() => setCancelVoucherModalOpen(false)}
                            color="primary"
                            autoFocus
                        >
                            Não
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog // cancelar pedido
                    open={cancelOrderModalOpen}
                    onClose={() => setCancelOrderModalOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Tem certeza que deseja cancelar o pedido? Esta ação
                            irá cancelar todos os vouchers e o pedido
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                cancelOrder()
                                setCancelOrderModalOpen(false)
                            }}
                            color="primary"
                        >
                            Sim
                        </Button>
                        <Button
                            onClick={() => setCancelOrderModalOpen(false)}
                            color="primary"
                            autoFocus
                        >
                            Não
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog // cancelar pedido parcialmente
                    open={cancelPartialOrderModalOpen}
                    onClose={() => setCancelPartialOrderModalOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Tem certeza que deseja cancelar o pedido parcialmente(80%)? Esta ação
                            irá cancelar todos os vouchers e o pedido
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                cancelPartialOrder()
                                setCancelPartialOrderModalOpen(false)
                            }}
                            color="primary"
                        >
                            Sim
                        </Button>
                        <Button
                            onClick={() => setCancelPartialOrderModalOpen(false)}
                            color="primary"
                            autoFocus
                        >
                            Não
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog // remover pedido
                    open={removeOrderModalOpen}
                    onClose={() => setRemoveOrderModalOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Tem certeza que deseja remover o pedido??? ATENÇÃO!!
                            Realizar e confirmar o cancelamento de voucher e
                            estorno de pagamento antes de remover o pedido. Caso
                            contrário, permanecerá uma venda ativa e status
                            incorreto.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                removeOrder()
                                setRemoveOrderModalOpen(false)
                            }}
                            color="primary"
                        >
                            Sim
                        </Button>
                        <Button
                            onClick={() => setRemoveOrderModalOpen(false)}
                            color="primary"
                            autoFocus
                        >
                            Não
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog // reenviar voucher
                    open={sendVoucherModalOpen}
                    onClose={() => setSendVoucherModalOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Deseja reenviar o voucher?
                        </DialogContentText>
                        <TextField
                            id="emailVoucher"
                            label="E-Mail"
                            value={emailVoucher}
                            onChange={(e) => setEmailVoucher(e.target.value)}
                            variant="outlined"
                            size="small"
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                sendVoucher(record.id, emailVoucher)
                                setSendVoucherModalOpen(false)
                            }}
                            color="primary"
                        >
                            Enviar
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog // adicionar comentário
                    open={commentModalOpen}
                    onClose={() => setCommentModalOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Adicionar comentário
                        </DialogContentText>
                        <TextField
                            id="comment"
                            label="Comentário"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            variant="outlined"
                            size="medium"
                            style={{
                                minWidth: 400,
                                height: 400,
                                minHeight: 400,
                            }}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                addComment(record.id, comment)
                                setCommentModalOpen(false)
                            }}
                            color="primary"
                        >
                            Enviar
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog // adicionar pagamento
                    open={paymentModalOpen}
                    onClose={() => setPaymentModalOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ minwidth: 400 }}
                >
                    <DialogContent style={{ minwidth: 400 }}>
                        <DialogContentText id="alert-dialog-description">
                            Adicionar pagamento
                        </DialogContentText>
                        <p>
                            <strong>Valor:</strong>
                        </p>
                        <p>{formatter.format(record.total || 0)}</p>
                        <p>
                            <strong>Forma de Pgto:</strong>
                        </p>
                        <p>
                            <Select
                                labelId="type"
                                id="type"
                                value={newPayment}
                                onChange={(e) =>
                                    handleChangeNewPayment(e.target.value)
                                }
                                label="Forma de Pagamento"
                            >
                                <MenuItem value={'Manual'}>
                                    Pagamento Manual
                                </MenuItem>
                                <MenuItem value={'Invoice'}>Faturado</MenuItem>
                                <MenuItem value={'Transfer'}>
                                    Pix via Link Paymee
                                </MenuItem>
                                <MenuItem value={'Link'}>
                                    Cartão via Link Pagtur
                                </MenuItem>
                            </Select>
                        </p>


                        <p>
                            {['Manual'].includes(newPayment) && <>
                                <input
                                    type="file"
                                    accept="image/png, image/jpeg, application/pdf"
                                    onChange={handleFile}
                                />
                                <p> O pagamento será considerado capturado automaticamente. Envie o arquivo do comprovante de pagamento (JPG, PNG ou PDF) acima e posteriormente comunique o Gate sobre este pagamento manual.                           </p>

                            </>}
                            <br />
                            {['Invoice'].includes(newPayment)
                                ? 'O pagamento será considerado Capturado automaticamente. Em seguida, efetue manualmente as reservas do pedido e reprocessar no Gate se aplicável.'
                                : ''}
                            {['Transfer', 'Link'].includes(newPayment)
                                ? 'Um novo link será gerado. A emissão será efetuada automaticamente após a confirmação do pagamento'
                                : ''}
                        </p>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                addPayment(record.id, newPayment)
                                setPaymentModalOpen(false)
                                uploadAttachment(record.id)
                            }}
                            color="primary"
                        >
                            Inserir Novo Pagamento
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog // enviar Gate
                    open={sendGateModalOpen}
                    onClose={() => setSendGateModalOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            CUIDADO! Tem certeza quer reprocessar o XML
                            novamente para o Gate? Verifique com Belvitur se
                            este pedido já não foi recebido no Gate antes de
                            seguir.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                sendGate(record.id)
                                setSendGateModalOpen(false)
                            }}
                            color="primary"
                        >
                            Enviar
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog // Adicionar tag
                    open={openNewTagDialog}
                    onClose={() => setOpenNewTagDialog(false)}
                    maxWidth="lg"
                >
                    <DialogContent>
                        <FormControl
                            variant="outlined"
                            className={classes.formControl}
                        >
                            <InputLabel id="demo-simple-select-outlined-label">
                                Nova Tag
                            </InputLabel>
                            <Select
                                labelId="newTag-label"
                                id="newTag"
                                value={newTag}
                                onChange={(e) =>
                                    handleChangeNewTag(e.target.value)
                                }
                                label="Nova Tag"
                            >
                                {tagOptions &&
                                    tagOptions.map((tag) => (
                                        <MenuItem value={tag.id}>
                                            {tag.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => newProductTag(record.id)}
                            color="primary"
                        >
                            Confirmar
                        </Button>
                        <Button
                            onClick={() => setOpenNewTagDialog(false)}
                            color="primary"
                            autoFocus
                        >
                            Cancelar
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog // remover tag
                    open={removeTagModalOpen}
                    onClose={() => setRemoveTagModalOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Tem certeza que deseja remover a tag?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                removeTag()
                                setRemoveTagModalOpen(false)
                            }}
                            color="primary"
                        >
                            Sim
                        </Button>
                        <Button
                            onClick={() => setRemoveTagModalOpen(false)}
                            color="primary"
                            autoFocus
                        >
                            Não
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog // Adicionar agente
                    open={openNewAgentDialog}
                    onClose={() => setOpenNewAgentDialog(false)}
                    maxWidth="lg"
                >
                    <DialogContent>
                        <FormControl
                            variant="outlined"
                            className={classes.formControl}
                        >
                            <InputLabel id="demo-simple-select-outlined-label">
                                Nova Agente
                            </InputLabel>
                            <Select
                                labelId="newAgent-label"
                                id="newAgent"
                                value={newAgent}
                                onChange={(e) =>
                                    handleChangeNewAgent(e.target.value)
                                }
                                label="Nova Agente"
                            >
                                {users &&
                                    users.map((user) => (
                                        <MenuItem value={user.id}>
                                            {user.email}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => newAgentOrder(record.id)}
                            color="primary"
                        >
                            Confirmar
                        </Button>
                        <Button
                            onClick={() => setOpenNewAgentDialog(false)}
                            color="primary"
                            autoFocus
                        >
                            Cancelar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    } else return null
}
