// export const API_URL = "https://api.melhorseguro.com.br";
export const API_URL = "https://api-test.melhorseguro.com.br";
// export const API_URL = "http://localhost:4000";
export const hotelCategories = [
    { id: 6, name: "Montanha" },
    { id: 5, name: "Praia" },
    { id: 4, name: "Natureza" },
    { id: 3, name: "Boutique" },
    { id: 2, name: "Resort" },
    { id: 1, name: "Geral" },
]
